<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar >
      <v-toolbar-title> Profile Tab </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-data-table :headers="headers" :items="profiletab_master_list" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
          </v-text-field>
          <v-btn color="indigo darken-4" style="color: white; margin-left: 30%; font-weight: bold;" dark @click="dialog = true;">Add Profile Tab</v-btn>
          <span style="width: 20px"></span>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-form v-model="valid">
                <v-row justify="space-between" style="background: linear-gradient(to top, #09203f 0%, #537895 100%);">
                  <span class="headline ml-8 mt-4 mb-4" style="color: white">{{ formTitle }}</span>
                  <v-btn class="mr-8 mt-4 mb-4" icon dark @click="dialog = false">
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-card-title>

                  <!-- <span class="headline">{{ formTitle }}</span> -->
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="editedItem.name" persistent-hint :hint="editedIndex == -1
        ? ''
        : ''
      " label="Enter Name" :rules="[(v) => !!v || 'required']" outlined>
                        </v-text-field>
                      </v-col>

                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                  <v-btn color="primary darken-1" dark @click="save" :disabled="!valid">Save</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.isactive="{ item }">
        <v-switch v-model="item.isactive" @change="activeswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
          </v-avatar>

          <v-avatar style="" size="25" class="edit-avatar ml-3">
            <v-icon color="red" size="20" @click="deletefun(item.id)">
              mdi-delete</v-icon></v-avatar>
        </div>
      </template>
    </v-data-table>

  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    overlay: false,
    errorMsg: {
      name: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    headers: [
      {
        text: "Sr No.",
        align: "left",
        sortable: false,
        value: "srno",
      },
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name",
      },
      {
        text: "Is active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    profiletab_master_list: [],
    editedIndex: -1,
    // addedItem: {
    //     language_super_master_list: ''
    // },
    editedItem: {
      id: "",
      name: "",
    },
    defaultItem: {
      id: "",
      name: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Profile Tab" : "Edit Profile Tab";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },

  // created () {
  //   this.initialize()
  // },
  mounted() {
    this.onLoad();
  },
  methods: {
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        // alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },

    editItem(item) {
      this.editedIndex = this.profiletab_master_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/AdminMaster/addProfileTab")
        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.profiletab_master_list = res.data.profiletab_master_list;
              this.overlay = false;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.hasWhiteSpace(this.editedItem.name)) {
        this.showSnackbar("red", "Please Enter Valid Name ");
        return;
      }

      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          axios
            .post("/AdminMaster/editProfileTab", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                //window.console.log(res.data.msg)
                Object.assign(
                  this.profiletab_master_list[this.editedIndex],
                  this.editedItem
                );

                this.showSnackbar(
                  "#4caf50",
                  "Profile Tab Updated Successfully..."
                ); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                // window.alert(res.data.status.code)
                // alert(res.data.status.message)
                this.showSnackbar("#b71c1c", "Profile Tab Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      } else {
        axios
          .post("/AdminMaster/saveProfileTab", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              // window.alert(res.data.status.code)
              this.profiletab_master_list.push(this.editedItem);
              //console.log('added snackbar');
              this.showSnackbar("#4caf50", "Profile Tab Added Successfully!!!"); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              // window.alert(res.data.status.code)
              // alert(res.data.status.message)
              this.showSnackbar("#b71c1c", "Profile Tab Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, //save()
    isValidated() {
      if (this.editedItem.name) {
        return true;
      } else {
        if (!this.editedItem.name) {
          this.errorMsg.name = true;
        }
        return false;
      }

    }, // .....end of isValidated()
    deletefun(item) {
      // console.log("a");
      const data = {
        deleteitem: item,
      };
      //  console.log("a1");
      axios
        .post("/AdminMaster/deleteProfileTab", data)
        .then((res) => {
          //  console.log("a11");
          if (res.data.msg == "200") {
            //    console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },

    activeswitch(item) {

      const data = {
        id: item.id,
      };

      axios
        .post("/AdminMaster/activeprofiletab", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },

  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>